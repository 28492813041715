import React, { useState, useEffect } from 'react';
import { Alert, Button, Row, Col } from 'react-bootstrap';
import { LocalStorageService } from '../services/LocalStorageService';

const Cookies = () => {
    const [acceptedCookies, setAcceptedCookies] = useState(false);

    const acceptCookies = () => {
        setAcceptedCookies(true);
        LocalStorageService.saveCookies(true)
    };

    useEffect(() => {
        const cookiesAccepted = LocalStorageService.validCookies();
        if (cookiesAccepted) {
            setAcceptedCookies(true);
        }
    }, []);

    if (acceptedCookies) {
        return null; // No mostrar el banner si ya se han aceptado las cookies
    }

    return (
        <div className="fixed-bottom">
            <Alert variant="info" className="cookie-banner" style={{ marginBottom: '0rem', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)', backgroundColor: '#ffffff' }}>
                <Row className="align-items-center">
                    <Col xs={12} md={9}>
                        <p className="mb-0"><strong>Utilizamos cookies</strong> para garantizar que obtengas la mejor experiencia en nuestro sitio web. <strong>Al aceptar, estás dando tu consentimiento</strong> para que utilicemos cookies. Para más información, consulta nuestros <a href="/politica/Terminos_condiciones.pdf" target="_blank" rel="noopener noreferrer">Términos y condiciones</a>.</p>
                    </Col>
                    <Col xs={12} md={3} className="text-center">
                        <Button onClick={acceptCookies} variant="outline-primary btn-sm col-12">Aceptar</Button>
                    </Col>
                </Row>
            </Alert>
        </div>
    );
};

export default Cookies;
