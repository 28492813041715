import React, { Suspense, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ProtectedRoute from "./utils/ProtectedRouter";
import { LocalStorageService } from "./components/services/LocalStorageService";
import { LoadScript } from '@react-google-maps/api';
import Cookies from "./components/layouts/Cookies.js";

// Preloader
const Preloader = React.lazy(() => import("./components/layouts/Preloader"));

// Home Pages
const Home = React.lazy(() => import("./components/pages/Home"));
// Pages
const Faq = React.lazy(() => import("./components/pages/Faq"));
const Contact = React.lazy(() => import("./components/pages/Contact"));
const Favorites = React.lazy(() => import("./components/pages/Favorites"));
const Notifications = React.lazy(() => import("./components/pages/Notifications"));
const Error = React.lazy(() => import("./components/pages/Error"));
const Login = React.lazy(() => import("./components/pages/Login"));
const ForgotPassword = React.lazy(() => import("./components/pages/ForgotPassword"));
const EditUser = React.lazy(() => import("./components/pages/EditUser"));
const Tutorial = React.lazy(() => import("./components/pages/Tutorial"));
const InfoComprehensivePrivacy = React.lazy(() => import("./components/layouts/InfoComprehensivePrivacy.js"));
const InfoDataDelection = React.lazy(() => import("./components/layouts/InfoDataDelection.js"));
const InfoTermsAndConditions = React.lazy(() => import("./components/layouts/InfoTermsAndConditions.js"));
const Register = React.lazy(() => import("./components/pages/Register"));
const PropertyRegistry = React.lazy(() => import("./components/pages/PropertyRegistry"));
const PanelControl = React.lazy(() => import("./components/pages/PanelControlUsuario.js"));
const QuienesSomos = React.lazy(() => import("./components/pages/QuienesSomos.js"));
// Listings
const Listingmap = React.lazy(() => import("./components/pages/Listingmap"));
const Listingdetailsone = React.lazy(() =>
  import("./components/pages/Listingdetailsone")
);
// App Imports
const LandingLY = React.lazy(() => import("./app/layouts/landing/LandingLY"));


const protectedRoutes = [
  // { path: "/", component: Home },
  // { path: "/login", component: Login },
  // { path: "/register", component: Register },
  // Agrega otras rutas aquí...
  { path: "/landing", component: LandingLY },
  /* Pages */
  { path: "/faq", component: Faq },
  { path: "/Configuraciones", component: EditUser },
  { path: "/error-404", component: Error },
  { path: "/PropertyRegistry", component: PropertyRegistry },
  
  { path: "/Favoritos", component: Favorites },
  { path: "/Notificaciones", component: Notifications },
];

const publicRoutes = [
  { path: "/", component: Home },
  { path: "/Perfil", component: PanelControl },
  { path: "/login", component: Login },
  { path: "/RecuperarContrasena", component: ForgotPassword },
  { path: "/contact", component: Contact },
  { path: "/register", component: Register },
  { path: "/listing-map", component: Listingmap },
  { path: "/QuienesSomos", component: QuienesSomos },
  { path: "/listing-details-v1", component: Listingdetailsone },
  { path: "/Tutorial", component: Tutorial },
  { path: "/AvisoDePrivacidadIntegral", component: InfoComprehensivePrivacy },
  { path: "/PoliticaEliminacionDeDatos", component: InfoDataDelection },
  { path: "/TerminosyCondiciones", component: InfoTermsAndConditions }
];

function App() {

  useEffect(() => {
    // Verificar la expiración del token al cargar la aplicación
    const isTokenExpired = LocalStorageService.isTokenExpired();

    if (isTokenExpired) {
      LocalStorageService.signOut(); // Borra los datos de la sesión
    }
  }, []);
  return (     
    <Router>
        <Suspense fallback={<div></div>}>
          <Preloader />
          <Switch>
            {publicRoutes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                exact
                component={route.component}
              />
            ))}
            {protectedRoutes.map((route, index) => (
              <ProtectedRoute
                key={index}
                path={route.path}
                exact
                redirectPath="/login" // Ruta de redirección si no está autenticado
                component={route.component}
              />
            ))}
          </Switch>
          <Cookies/>
        </Suspense>
      </Router>
  );
}

export default App;
