import React from "react";
import { Route, Redirect } from "react-router-dom";
import { LocalStorageService } from "../components/services/LocalStorageService";

const ProtectedRoute = ({ canActivate, redirectPath, component: Component, ...rest }) => {
    const isTokenExpired = LocalStorageService.isTokenExpired();

    if (isTokenExpired) {
        LocalStorageService.signOut(); // Borra los datos de la sesión
    }

    return (
        <Route
            {...rest}
            render={({ location }) =>
                !isTokenExpired ? (
                    <Component {...rest} />
                ) : (
                    <Redirect
                        to={{
                            pathname: redirectPath,
                            state: { from: location },
                        }}
                    />
                )
            }
        />
    );
};

export default ProtectedRoute;
