export class LocalStorageService {

  static isTokenExpired() {
    const token = this.getToken();
    if (!token) {
      // No hay token, por lo que consideramos que está caducado
      return true;
    }

    // Decodificar el token para acceder a la marca de tiempo de expiración
    const tokenPayload = JSON.parse(atob(token.split('.')[1]));
    const expirationTime = tokenPayload.exp * 1000; // Convertir a milisegundos

    // Comparar la marca de tiempo de expiración con la hora actual
    const currentTime = Date.now();
    return currentTime > expirationTime;
  }


  static signOut() {
    localStorage.removeItem('TOKEN_KEY');
    localStorage.removeItem('USER_KEY');
    localStorage.removeItem('REFRESH_TOKEN_KEY');
    localStorage.removeItem('URL');
  }

  static saveCredentials(user, accessToken, refreshToken) {
    localStorage.setItem('USER_KEY', JSON.stringify(user));
    localStorage.setItem('TOKEN_KEY', accessToken);
    localStorage.setItem('REFRESH_TOKEN_KEY', refreshToken);
  }

  // Guardamos el token en la Local Store
  static saveTokens(accessToken, refreshToken) {
    localStorage.setItem('TOKEN_KEY', accessToken);
    localStorage.setItem('REFRESH_TOKEN_KEY', refreshToken);
  }
  // Obtenemos el token
  static getToken() {
    return localStorage.getItem('TOKEN_KEY');
  }

  static getRefreshToken() {
    return localStorage.getItem('REFRESH_TOKEN_KEY');
  }

  static getUser() {
    const userJson = localStorage.getItem('USER_KEY');
    return userJson ? JSON.parse(userJson) : null;
  }

  static getUrlSocialMedia() {
    return localStorage.getItem('URL');
  }


  static saveUser(userinfo, url) {
    localStorage.setItem('USER_KEY', JSON.stringify(userinfo));
    if (url) localStorage.setItem('URL', url);
  }

  static saveCookies(accepted) {
    if (typeof accepted !== 'boolean') {
      throw new Error('El valor de las cookies debe ser un booleano');
    }
    const cookiesValue = accepted ? 'accepted' : 'rejected';
    localStorage.setItem('ACCEPTED_COOKIES', cookiesValue);
  }
  static validCookies() {
    return localStorage.getItem('ACCEPTED_COOKIES');
  }

  static isLoggedIn() {
    const token = this.getToken();
    return token !== null && token !== undefined && token !== '';
  }


}

